.navbar-login
{
    width: 305px;
    padding: 10px;
    padding-bottom: 0px;
}

.navbar-login-session
{
    padding: 10px;
    padding-bottom: 0px;
    padding-top: 0px;
}

.icon-size
{
    font-size: 87px;
}
#profile-dropdown img { height: 45px; border-radius: 100px; margin-top: -15px; margin-bottom: -15px;}

#logout {width: 100%}
/* https://work.smarchal.com/twbscolor/3.3.7/css/337ab7265b89ffffffffffff0 */
