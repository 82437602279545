body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.selected td {
    background-color: #d9edf7;
}

