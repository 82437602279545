.scroll-header {
    font-weight: bold;
    padding: 8px;
    margin-right: 15px;
}
.scroll-container {
    border-top: solid #ddd 2px;
    max-height: 256px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.scroll-container > div {
    border: 1px solid #dddd;
    border-collapse: collapse;
    padding: 8px;
}
.scroll-container > div:nth-child(odd) {
    background: #eee;
}
.scroll-container > div.selected {
    color: #31708f;
    background: #d9edf7;
}
